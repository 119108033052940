import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "What Is Rapid Prototyping?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Rapid prototyping is a set of techniques used to quickly fabricate a scale model of an assembly or physical part using three-dimensional computer CAD data. Construction of the assembly or part is usually done using “additive layer manufacturing” tech or 3D printing."
      }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
      "@type": "Question",
      "name": "Why Do We Use Rapid Prototyping?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "3D rapid prototyping advancements allow for lower-cost and faster prototypes and model fabrication by eliminating expensive tooling and manpower which allows inventors and companies to bring their designs and products to market faster than the competition. This is what Codestaff does every day. We can help you."
      }
    }
}

const RapidPrototyping = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Rapid Prototyping Specialists.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Rapid Prototyping Specialists. Top
                                    companies and start-ups choose Codestaff Rapid Prototyping experts
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Rapid Protoyping Specialist now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE RAPID PROTOTYPING SPECIALISTS' banner='/rapid-prototyping.png' bannerAlt='rapid prototyping banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default RapidPrototyping